/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/firestore');
require('firebase/storage');
require('firebase/analytics');

const { firebase: firebaseConfig } = require('./config');

exports.onClientEntry = () => {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
};
