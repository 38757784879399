// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-admin-about-jsx": () => import("../src/pages/admin/about.jsx" /* webpackChunkName: "component---src-pages-admin-about-jsx" */),
  "component---src-pages-admin-contact-jsx": () => import("../src/pages/admin/contact.jsx" /* webpackChunkName: "component---src-pages-admin-contact-jsx" */),
  "component---src-pages-admin-index-jsx": () => import("../src/pages/admin/index.jsx" /* webpackChunkName: "component---src-pages-admin-index-jsx" */),
  "component---src-pages-admin-news-jsx": () => import("../src/pages/admin/news.jsx" /* webpackChunkName: "component---src-pages-admin-news-jsx" */),
  "component---src-pages-admin-reel-jsx": () => import("../src/pages/admin/reel.jsx" /* webpackChunkName: "component---src-pages-admin-reel-jsx" */),
  "component---src-pages-contact-jsx": () => import("../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-reel-jsx": () => import("../src/pages/reel.jsx" /* webpackChunkName: "component---src-pages-reel-jsx" */)
}

